// Trên 1000
@media (min-width: 1000px){
    .wrapper {
        position: relative;
        width: 100%;
        max-width: 1920px;
        aspect-ratio: 3.2;
    
    
        .slick-slide {
            .slide {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
    
                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    
        .dots-container {
            position: absolute;
            bottom: -100px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
    
            .dot {
                margin: 0 10px;
                cursor: pointer;
                transition: background-color 0.3s ease;
    
                &.active-dot {
                    color: var(--text-Blue);
                }
            }
        }
    
        .next,
        .prev {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #282828;
            font-size: 30px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 1;
    
            &.next {
                right: -150px;
            }
    
            &.prev {
                left: -150px;
            }
        }
    
        @media screen and (max-width: 480px) {
            .wrapper {
                width: 100%;
                min-width: 345px;
                height: 150px;
    
                .next,
                .prev {
                    background-color: #00215768;
                    backdrop-filter: blur(2px);
                }
            }
        }
    }
}
// Dưới 1000
@media (max-width: 1000px) {
    .wrapper {
        position: relative;
        width: 100%;
        max-width: 1920px;
        aspect-ratio: 3.2;
    
    
        .slick-slide {
            .slide {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
    
                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    
        .dots-container {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -70px;
            left: 50%;
            width: 100vw;
            transform: translateX(-50%);
            display: flex;
    
            .dot {
                margin: 0 10px;
                cursor: pointer;
                transition: background-color 0.3s ease;
    
                &.active-dot {
                    color: var(--text-Blue);
                }
            }
        }
    
        .next,
        .prev {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #282828;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 1;
    
            &.next {
                right: -50px;
            }
    
            &.prev {
                left: -50px;
            }
        }
    
        @media screen and (max-width: 480px) {
            .wrapper {
                width: 100%;
                min-width: 345px;
                height: 150px;
    
                .next,
                .prev {
                    background-color: #00215768;
                    backdrop-filter: blur(2px);
                }
            }
        }
    }
}