// Trên 1000
@media (min-width: 1000px){
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        user-select: none;
        position: relative;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    
        .dots {
            position: fixed;
            top: 50%;
            right:30px;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            gap: 15px;
            transition: all 0.5s ease;
        }
        
        .dot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7px;
            height: 7px;
            background-color: #959595;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.5s ease;
        }
        
        .dot.active {
            width: 14px;
            height: 14px;
            margin-left: -3px;
            background-color: #00b8ff; 
        }
        
        .container {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .Sticky-Header {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                background-color: white;
                z-index: 1000;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 10px 20px;
                // border-bottom: 1px solid #e5e5e5;

                .header-nav_logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 160px;
                    height: auto;
                    padding-bottom: 50px;
                    cursor: pointer;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        }
                }
                .list-nav {
                    display: flex;
                    align-items: center;
                    gap: 120px;
                    
                    .list-btn {
                        color: #00b8ff;
                        font-size: 18px;
                        padding-bottom: 4px;
                        cursor: pointer;
                        position: relative;
                        text-transform: uppercase;

                        &.active {
                            color: #00b8ff;
                           
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            width: 100%;
                            height: 2px;
                            background-color: transparent;
                            transition: background-color 0.3s ease;
                        }
            
                        &.active::after { 
                            background-color: #00b8ff;
                        }

                        &:hover{
                            color: #282828;
                        }
                    }
                }
            }
    
            .sider1 {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100vw;
                height: 100vh;
                gap: 30px;
    
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 70px;
        
                    .header-nav_logo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 250px;
                        height: auto;
                        padding-bottom: 100px;
        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
        
                    .sider1, .sider2, .sider3, .sider4, .sider5 {
                        display: none;
            
                        &.active {
                            display: flex;
                        }
                    }
                }
    
                .text-sider {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 300px;
                    line-height: 1.5;
                    padding-bottom: 50px;
    
                    h1 {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 800;
                        font-size: 10rem;
                    }
    
                    .box-contact {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        
                        a{
                            color: #282828;
                            font-size: 16px;
                            text-decoration: none;
                        }
                    }
                }
    
                .list-nav {
                    display: flex;
                    align-items: center;
                    gap: 50px;
    
                    .list-btn {
                        color: #00b8ff;
                        font-size: 24px;
                        padding-bottom: 4px;
                        cursor: pointer;
                        text-transform: uppercase;

                        &:hover{
                            color: #282828;
                        }
                    }
                }
    
                .button-sider {
                    height: 56px;
                    line-height: 56px;
                    color: #fff;
                    padding-left: 40px;
                    padding-right: 40px;
                    background: #00b8ff;
                    display: inline-block;
                    border-radius: 300px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    cursor: pointer;

                    a{
                        color: #fff;
                    }
    
                    &:hover {
                        color: #fff;
                        background: #0e76bc;
                    }
                }
    
                .outer-btn {
                    font-size: 1.8rem;
                    color: #959595;
                    cursor: pointer;
    
                    .icon {
                        font-size: 4rem;
                        animation: bounce 1s infinite alternate;
                    }
                }
            }
        }
    
        .sider2 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                margin-right: -15px;
                margin-left: -15px;
                width: 70vw;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 4rem;
                    width: 40%;
                    margin-bottom: 3rem;
                
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 60px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 50px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.8rem;
    
                        .btn-view {
                            height: 30px;
                            line-height: 30px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            a{
                            color: #fff; 
                            }

                            &:hover {
                                color: #fff;
                                background: #0e76bc;
                            }
                        }
                    }
                }
    
                .sider-view{
                    width: 936px;
                    height: 508px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 30px 157px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 620px;
                        height: 400px;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }
        }   
        .sider3 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                margin-right: -15px;
                margin-left: -15px;
                width: 70vw;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 4rem;
                    width: 40%;
                    margin-bottom: 3rem;
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 60px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 50px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.8rem;
    
                        .btn-view {
                            height: 30px;
                            line-height: 30px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            &:hover {
                                color: #fff;
                                background: #0e76bc;
                            }

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    width: 936px;
                    height: 508px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 30px 157px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 620px;
                        height: 400px;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }
        }
        .sider4 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                margin-right: -15px;
                margin-left: -15px;
                width: 75vw;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 4rem;
                    width: 50%;
                    margin-bottom: 3rem;
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 60px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 50px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.8rem;
    
                        .btn-view {
                            height: 30px;
                            line-height: 30px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            &:hover {
                                color: #fff;
                                background: #0e76bc;
                            }

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    width: 936px;
                    height: 508px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 30px 157px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 620px;
                        height: 400px;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }        
        }
        .sider5 {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                margin-right: -15px;
                margin-left: -15px;
                width: 75vw;
                height: 70vh;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: flex-start;
                    font-size: 4rem;
                    width: 50%;
                    margin-bottom: 3rem;
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 60px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 50px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.8rem;
    
                        .btn-view {
                            height: 30px;
                            line-height: 30px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            &:hover {
                                color: #fff;
                                background: #0e76bc;
                            }

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    width: 800px;
                    height: 434px;
                    background-size: 100%;
                    padding: 120px 57px;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 450px;
                        height: 308px;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }
            
            .wrapper2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100vw;
                height: 30vh;
                padding: 15px 0;
                background-image: linear-gradient(to top, #5eb9e0, #fff);
                background-size: cover;
                background-repeat: no-repeat;
                color: var(--text-color);
                border-top: 1px solid #e7e7e7a4;
                font-weight: 300;
                font-size: 1.5rem;
                margin: 0 auto;
            
                .footer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 30px;
                    width: 1600px;
                    padding: 0 20px;
                    margin: 0 auto;
            
                    .footer-row {
                        display: flex;
                        align-items: center;
                        text-align: left;
                        gap: 100px;
            
                        & + .footer-row {
                            border-top: 1px solid #fff;
                        }
            
                        .footer-item {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            
                            .content{
                                width: 100%;
                                display: block;
    
                            }
                            .footer-add{
                                width: 100%;
                                padding-top: 0px;
            
                                .contact-info{
                                    line-height: 27px;
                                    
                                    .icon{
                                        margin-right: 10px;
                                    }
                                }
            
                            }
            
                            .certification {
                                width: 300px;
                            }
            
                            .security {
                                width: 200px;
                            }
                            .responsibleGaming {
                                width: 200px;
                            }
            
                            .logo {
                                width: 200px;
                            }
            
                            p {
                                line-height: 1.3;
                            }
            
                            strong {
                                font-weight: 500;
                            }
            
                            .check {
                                color: #00b8ff;
                            }
            
                            &.link {
                                margin-top: 10px;
                                padding-top: 10px;
                                flex-direction: row;
                                flex: 2;
            
                                a {
                                    color: var(--text-color);
                                    text-decoration: underline;
                                    font-weight: 500;
                                }
                                img {
                                    width: 30px;
                                    height: 30px;
                                    object-fit: cover;
                                }
                            }
            
                            &.aa {
                                flex: 1;
                            }
            
                            &.iconmedia {
                                flex: 1;
                                padding-left: 370px;
                            }
                        }
            
            
                    }
                }
            
                .content-footer {
                    width: 1600px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: auto;
                    margin: 0 auto;
            
                    .footer-child {
                        display: flex;
                        // flex-direction: column;
                        align-items: center;
                        max-width: 100px;
                        padding: 10px 10px;
            
                        img {
                            height: auto;
                            max-width: 100%;
                        }
                    }
                }
            
                .copyright {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: var(--text-color);
                    padding-top: 20px;
            
                    a {
                        color: var(--text-color);
                    }
                }
            }
        }
    
    }
}
// Dưới 1000
@media (max-width: 1000px){
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        user-select: none;
        position: relative;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    
        .dots {
            position: fixed;
            top: 50%;
            right:30px;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            gap: 15px;
            transition: all 0.5s ease;
            display: none;
        }
        
        .dot {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7px;
            height: 7px;
            background-color: #959595;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.5s ease;
        }
        
        .dot.active {
            width: 14px;
            height: 14px;
            margin-left: -3px;
            background-color: #00b8ff; 
        }
        
        .container {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .Sticky-Header {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                background-color: white;
                z-index: 1000;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                // border-bottom: 1px solid #e5e5e5;

                .header-nav_logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    padding-bottom: 10px;
                    padding-top: 10px;
                    cursor: pointer;
                    border-bottom: 1px solid #e5e5e5;
                    
                    img {
                        width: 120px;
                        height: 100%;
                        object-fit: cover;
                        }
                }
                .list-nav {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow-wrap: break-word;
                    gap: 20px;
                    padding-top: 10px;
                    padding-bottom: 8px;
                    flex-wrap: wrap;
                    
                    .list-btn {
                        overflow-wrap: break-word;
                        color: #00b8ff;
                        font-size: 14px;
                        padding-bottom: 4px;
                        cursor: pointer;
                         position: relative;
                        text-transform: uppercase;

                        &.active {
                            color: #00b8ff;
                           
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            width: 100%;
                            height: 2px;
                            background-color: transparent;
                            transition: background-color 0.3s ease;
                        }
            
                        &.active::after { 
                            background-color: #00b8ff;
                        }

                        &:hover{
                            color: #282828;
                        }
                    }
                }
            }
    
            .sider1 {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100vw;
                height: 100vh;
    
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;
        
                    .header-nav_logo {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 130px;
                        height: auto;
                        padding-bottom: 30px;
        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
        
                    .sider1, .sider2, .sider3, .sider4, .sider5 {
                        display: none;
            
                        &.active {
                            display: flex;
                        }
                    }
                }
    
                .text-sider {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 300px;
                    line-height: 1.5;
                    padding-bottom: 20px;
    
                    h1 {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 800;
                        font-size: 5rem;
                    }
    
                    .box-contact {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        
                        a{
                            color: #282828;
                            font-size: 15px;
                            text-decoration: none;
                        }
                    }
                }
    
                .list-nav {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 30px;
    
                    .list-btn {
                        color: #00b8ff;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
    
                .button-sider {
                    height: 46px;
                    line-height: 46px;
                    color: #fff;
                    padding-left: 25px;
                    padding-right: 25px;
                    background: #00b8ff;
                    display: inline-block;
                    border-radius: 300px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    cursor: pointer;
    
                    a{
                        color: #fff;
                    }
                    
                    &:hover {
                        color: #fff;
                        background: #0e76bc;

                    }
                }
    
                .outer-btn {
                    font-size: 1.5rem;
                    color: #959595;
                    cursor: pointer;
    
                    .icon {
                        font-size: 4rem;
                        padding-top: 20px;
                        animation: bounce 1s infinite alternate;
                    }
                }
            }
        }
    
        .sider2 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-right: -15px;
                margin-left: -15px;
                width: 100%;
                margin-top: -60px;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 2.5rem;
                    width: 100%;
                    margin-bottom: 70px;                 
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.3rem;
    
                        .btn-view {
                            height: 25x;
                            line-height: 25px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 90vw;
                    height: 182px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 19px 60px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60vw;
                        height: auto;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }
        }
        
        .sider3 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-right: -15px;
                margin-left: -15px;
                width: 100%;
                margin-top: -60px;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 2.5rem;
                    width: 100%;
                    margin-bottom: 70px;                 
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.3rem;
    
                        .btn-view {
                            height: 25x;
                            line-height: 25px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 90vw;
                    height: 182px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 19px 60px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60vw;
                        height: auto;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            }
        }
        .sider4 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-right: -15px;
                margin-left: -15px;
                width: 100%;
                margin-top: -60px;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 2.5rem;
                    width: 100%;
                    margin-bottom: 70px;                 
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.3rem;
    
                        .btn-view {
                            height: 25x;
                            line-height: 25px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 90vw;
                    height: 182px;
                    background: url(../../assets/images/computer.png) no-repeat;
                    background-size: 100%;
                    padding: 19px 60px;
                    object-fit: cover;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60vw;
                        height: auto;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
    
                }
            } 
        }
        .sider5 {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100vh;
            width: 100%;
    
            .sider-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100vh;
                margin-right: -15px;
                margin-left: -15px;
                width: 100%;
                margin-top: -60px;
    
                .sider-txt {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 2.5rem;
                    width: 100%;
                    margin-bottom: 20px;                 
    
    
                    .quang_cao {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromScale 0.7s ease-out 0.5s forwards;
                    }
                
                    .hien_thi {
                        color: #00b8ff;
                        font-family: Myriad Pro;
                        font-weight: 700;
                        padding-bottom: 30px;
                        animation: fadeInFromTranslate 0.7s ease-out 0.7s forwards;
                    }
                    .outer-view{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 1.3rem;
    
                        .btn-view {
                            height: 25x;
                            line-height: 25px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 5px;
                            background: #00b8ff;
                            color: #fff;
                            font-size: 1.3rem;
                            display: inline-block;
                            margin-left: 3px;
                            cursor: pointer;

                            a{
                                color: #fff; 
                                }
                        }
                    }
                }
    
                .sider-view{
                    width: 90vw;
                    height: 182px;
                    background-size: 100%;
                    padding: 19px 60px;
    
                    .view{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 230px;
                        height: 138px;
                        transition: all 0.3s ease;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            
            .wrapper2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100vw;
                height: 100%;
                padding: 15px 0;
                background-image: linear-gradient(to top, #5eb9e0, #fff);
                background-size: cover;
                background-repeat: no-repeat;
                color: var(--text-color);
                border-top: 1px solid #e7e7e7a4;
                font-weight: 300;
                font-size: 1.2rem;
                margin: 0 auto;
            
                .footer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 30px;
                    width: 100vw;
                    padding: 0 10px;
                    margin: 0 auto;
            
                    .footer-row {
                        display: flex;
                        text-align: left;
                        flex-direction: column;
            
                        & + .footer-row {
                            border-top: 1px solid #fff;
                        }
            
                        .footer-item {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            
                            .content{
                                width: 100%;
                                display: block;
    
                            }
                            .footer-add{
                                display: flex;
                                flex-direction: column;
                                text-align: left;
                                width: 100%;
                                padding-top: 10px;
            
                                .contact-info{
                                    line-height: 27px;
                                    
                                    .icon{
                                        margin-right: 10px;
                                    }
                                }
            
                            }
            
                            .logo {
                                width: 170px;
                            }
            
                            strong {
                                font-weight: 500;
                            }
            
                            .check {
                                color: #00b8ff;
                            }
                        }
            
            
                    }
                }
            
                .content-footer {
                    width: 100vw;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: auto;
                    margin: 0 auto;
            
                    .footer-child {
                        display: flex;
                        // flex-direction: column;
                        align-items: center;
                        max-width: 100px;
                        padding: 10px 10px;
            
                        img {
                            height: auto;
                            max-width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            
                .copyright {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: var(--text-color);
                    padding-top: 10px;
            
                    a {
                        color: var(--text-color);
                    }
                }
            }
        }
    
    }
}

@keyframes fadeInFromScale {
    0% {
        transform: scale(-0.1);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeInFromTranslate {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    50% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}