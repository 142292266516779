@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,700;1,200;1,300;1,400;1,500;1,700&display=swap');

:root {
    --primary: #f1ac28;
    --primary-hover: #d46700;
    --background-hover: rgb(22, 24, 35, 0.03);
    --background: #fff;
    --background1: #e5fbff;
    --background2: #a4f1ff;
    --background3: #00b8ff;
    --background4: linear-gradient(to top, #afe1f7, #e5fbff);
    --backgroundWhite: #fff;
    --backgroundBlack: #292929;
    --backgroundBlack2: #292e3b;
    --backgroundBlack3: #282a2d;
    --background-menu: linear-gradient(to top, #5eb9e0, #e5fbff00);
    --background-footer: linear-gradient(to top, #5eb9e0, #e5fbff);
    --background-footer-2: linear-gradient(to top, #5eb9e0, #292e3b);
    --background-gold: linear-gradient(to left, #edcd5d, #f7f5a8, #edcd5d);
    --background-blue: linear-gradient(to left, #208dff, #2fdcec, #208dff);
    --background-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    --black: #000;
    --white: #fff;
    --grey: #4f4f4f;
    --text-color: #333;
    --text-white: #fff;
    --text-gold: #fefda9;
    --text-Blue: #00b8ff;
    --Blue: #00b8ff;
    --bacground-blog: #a4f1ff;
    --icon-color: #4942e4;
    --background-color: #8c0000;
    --background-color-2: #c90000;
    --background-color-hover: #ffffff85;
    --background-btn-img: linear-gradient(0, #ff6b26, #ffd23d);
    --background-btn-img-hover: linear-gradient(0, #ff6b26d7, #ffd23d);
    --background-btn-img-primary: linear-gradient(0, #da1212, #fabe1b);
    --background-btn-img-primary-hover: linear-gradient(0, #208dff, #2fdcec);
    --background-btn-img-primary-hover-2: linear-gradient(0, #d3d3d3, #fff);
    $background-blue: linear-gradient(to left, #208dff, #2fdcec, #208dff);

    --font-family: 'Roboto', sans-serif;

    //DefaultLayout
    --default-layout-header-height: 60px;
    --default-layout-width: 1150px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    font-size: 1.6rem;
    line-height: 1.5rem;
    text-rendering: optimizeSpeed;
    overflow-x: hidden;
    background: url(../../assets/images/bgd-pc.png);
    background-position: bottom right !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: contain !important;
}

#root {
    height: 100%;
}

input,
button {
    outline: none;
    border: none;
    margin-top: 10px;
}

a {
    color: var(--black);
    text-decoration: none;
}

// .App {
//     width: 100%;
//     min-height: 1000px;
// }
