.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* Ngăn người dùng chọn văn bản */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    .container {
        // background: url(../../assets/images/bgd-pc.png);
        // background-position: bottom right !important;
        // background-repeat: no-repeat !important;
        // background-attachment: fixed !important;
        // background-size: contain !important;
    }
}
